<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Aws from "@/services/Aws";
    import Swal from "sweetalert2";

    /**
     * Instance Details component
     */
    export default {
        components: {
            Layout,
            PageHeader
        },
        page: {
            title: "AWS Instance",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "AWS Instance",
                items: [
                    {
                        text: "AWS Instances",
                    },
                    {
                        text: "Instance Details",
                        active: true,
                    },
                ],
                instanceData: [],
                error: null,
                modalData: {},
                toggleProfile: false,
                showLoader: false
            };
        },
        async mounted() {
            this.getInstanceDetails()
        },
        methods: {

            getInstanceDetails() {

                this.showLoader = true;
                Aws.getInstanceDetails(this.$route.params.id).then((response) => {
                    this.instanceData = response.data.data;
                }).catch((error) => {
                this.error = error.response.data.error
                    ? error.response.data.error
                    : "";
                }).finally(() => {
                    this.showLoader = false;
                });
            },

            getTagName(tags) {
                if(!tags.length){
                   return '';
                }
                // Find the tag with the Key "Name"
                const nameTag = tags.find(tag => tag.Key === "Name");
                return nameTag ? nameTag.Value : "";  // Return the value or a fallback
            },

            startInstance(){
                Swal.fire({
                    title: "Start Instance <br>" + this.$route.params.id,
                    icon: "warning",
                    html: `
                        <p>Type "Start" to confirm:</p>
                        <input type="text" id="startConfirmation" class="swal2-input" autocomplete="off">
                    `,
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Start",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        const confirmationText = Swal.getPopup().querySelector(
                            "#startConfirmation"
                        ).value;
                        if (confirmationText.toLowerCase() !== "start") {
                            Swal.showValidationMessage('You must type "Start" to confirm');
                            return false;
                        }
                        return Aws.startInstance(this.$route.params.id)
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                let errorMsg = error.response.data.error
                                    ? error.response.data.error
                                    : "";
                                this.failedmsg("Fail!", errorMsg);
                            });
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const res = result.value.data ? result.value.data.success : false;
                        const error = result.value.data.error
                            ? result.value.data.error
                            : "Failed";

                        if (res) {
                            this.successmsg('Start Instance', 'Instance is successfully started')
                            setTimeout(() => {
                                this.getInstanceDetails();
                            }, 2000);
                        } else {
                            this.warningmsg("Fail!", error);
                        }
                    }
                });

            },

            stopInstance(){

                Swal.fire({
                    title: "Stop Instance <br>" + this.$route.params.id,
                    icon: "warning",
                    html: `
                        <p>Type "Stop" to confirm:</p>
                        <input type="text" id="stopConfirmation" class="swal2-input" autocomplete="off">
                    `,
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Stop",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        const confirmationText = Swal.getPopup().querySelector(
                            "#stopConfirmation"
                        ).value;
                        if (confirmationText.toLowerCase() !== "stop") {
                            Swal.showValidationMessage('You must type "Stop" to confirm');
                            return false;
                        }
                        return Aws.stopInstance(this.$route.params.id)
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                let errorMsg = error.response.data.error
                                    ? error.response.data.error
                                    : "";
                                this.failedmsg("Fail!", errorMsg);
                            });
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const res = result.value.data ? result.value.data.success : false;
                        const error = result.value.data.error
                            ? result.value.data.error
                            : "Failed";

                        if (res) {
                            this.successmsg('Stop Instance', 'Instance is successfully stopped')
                            setTimeout(() => {
                                this.getInstanceDetails();
                            }, 2000);
                        } else {
                            this.warningmsg("Fail!", error);
                        }
                    }
                });
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!this.error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body" v-if="!showLoader">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <a v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'stopped'"
                                        class="dropdown-item"
                                        :class="{ 'disabled': instanceData.stateChange == 0 }"
                                        href="javascript:void(0);"
                                        @click="startInstance()"
                                >Start Instance</a>
                                <a v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'running'"
                                        class="dropdown-item"
                                        :class="{ 'disabled': instanceData.stateChange == 0 }"
                                        href="javascript:void(0);"
                                        @click="stopInstance()"
                                >Stop Instance</a>
                            </b-dropdown>
                            <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getInstanceDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h3 class="mt-3 mb-1" v-if="instanceData.Tags">
                                {{getTagName(instanceData.Tags)}}
                            </h3>
                            <small class="mt-3 mb-1">{{instanceData.InstanceId}}</small>
                            <p class="mt-2 mb-1"></p>
                            <div class="mt-2">
                                <div class="font-size-18" v-if="instanceData && instanceData.State && instanceData.State.Name"
                                     v-html="format_status(instanceData.State.Name)"></div>
                            </div>
                        </div>


                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Instance Information</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Instance Type :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{instanceData.InstanceType}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Monitoring State :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" v-if="instanceData && instanceData.Monitoring">{{instanceData.Monitoring.State}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Public Address :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" v-if="instanceData.PublicIpAddress">{{instanceData.PublicIpAddress}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Private Address :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" v-if="instanceData.PrivateIpAddress">{{instanceData.PrivateIpAddress}}</span>
                                </p>
                            </div>

                            <hr />

                            <b-button v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'stopped'"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="primary"
                                      title="Start Instance"
                                      @click="startInstance()"
                                      :class="{ 'disabled': instanceData.stateChange == 0 }"
                            ><i class="uil-play"></i> Start Instance</b-button>

                            <b-button v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'running'"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="danger"
                                      title="Stop Instance"
                                      @click="stopInstance()"
                                      :class="{ 'disabled': instanceData.stateChange == 0 }"
                            ><i class="uil-square"></i> Stop Instance</b-button>

                            <div class="mt-5">
                                <p class="mb-1">
                                    Launch Time : <span class="mw-80" v-if="instanceData.LaunchTime">{{formatDate(instanceData.LaunchTime)}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex align-items-center justify-content-center" v-else>
                        <b-spinner large></b-spinner>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>

                            </b-dropdown>
                            <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getInstanceDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h5 class="mt-3 mb-1" v-if="instanceData.Tags">
                                {{getTagName(instanceData.Tags)}}
                            </h5>
                            <small class="mt-3 mb-1">{{instanceData.InstanceId}}</small>

                            <div class="mt-4">
                                <div class="font-size-18" v-if="instanceData && instanceData.State && instanceData.State.Name"
                                     v-html="format_status(instanceData.State.Name)"></div>
                            </div>
                        </div>
                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <b-button v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'stopped'"
                                    class="mb-2 d-block m-auto mwb-11"
                                    variant="primary"
                                    title="Send Push Notification"
                                    @click="startInstance()"
                                    :class="{ 'disabled': instanceData.stateChange == 0 }"
                            ><i class="uil-play"></i> Start Instance</b-button>

                            <b-button v-if="instanceData && instanceData.State && instanceData.State.Name && instanceData.State.Name == 'running'"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="danger"
                                      title="Stop Instance"
                                      @click="stopInstance()"
                                      :class="{ 'disabled': instanceData.stateChange == 0 }"
                            ><i class="uil-square"></i> Stop Instance</b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Extended Details</span>
                            </template>
                            <template v-if="!showLoader">
                                <div class="row">

                                    <div class="col-sm-12 col-md-3">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Image Id :</p>
                                            <p class="font-size-16">{{instanceData.ImageId}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Platform Details :</p>
                                            <p class="font-size-16">{{instanceData.PlatformDetails}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Architecture :</p>
                                            <p class="font-size-16">{{instanceData.Architecture}}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-3">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Boot Mode :</p>
                                            <p class="font-size-16">{{instanceData.BootMode}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <p class="mb-1 text-strong">Notes:</p>
                                        <p class="font-size-16">{{instanceData.note}}</p>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>

                    </b-tabs>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>{{this.error}}</h4>
                </div>
            </div>
        </div>
        <!-- end row -->

        <!-- MODALS -->

        <!-- END  MODALS -->
    </Layout>
</template>
